$('document').ready(function () {
    // ----------- variables ----------- //
    var trigger = $('#burger--js');
    var isClosed = false;
    var heroSection = $('.c-hero');
    var aboutSection = $('.c-section--about');
    var workSection = $('.c-section--our-work');
    var testimonialsSection = $('.c-section--testimonials');
    var packagesSection = $('.c-section--packages');
    var contactSection = $('.c-section--contact');

    // ----------- functions ----------- //
    function checkSize() {
        if ($(".burger").css("display") == "none" ) {
            console.log('desktop');
            $('.c-nav__list-item--home p').attr('id', 'home-scroll');
            $('.c-nav__list-item--about p').attr('id', 'about-scroll');
            $('.c-nav__list-item--work p').attr('id', 'work-scroll');
            $('.c-nav__list-item--testimonials p').attr('id', 'testimonials-scroll');
            $('.c-nav__list-item--packages p').attr('id', 'packages-scroll');
            $('.c-nav__list-item--contact p').attr('id', 'contact-scroll');
            $('.c-nav__underline').css({'display': 'none'});
        } else {
            console.log('mob');
            $('.c-nav__list-item--home p').attr('id', 'home-scroll-mob');
            $('.c-nav__list-item--about p').attr('id', 'about-scroll-mob');
            $('.c-nav__list-item--work p').attr('id', 'work-scroll-mob');
            $('.c-nav__list-item--testimonials p').attr('id', 'testimonials-scroll-mob');
            $('.c-nav__list-item--packages p').attr('id', 'packages-scroll-mob');
            $('.c-nav__list-item--contact p').attr('id', 'contact-scroll-mob');
            $('.c-nav__underline').css({'display': 'block'});
        }
    }

    function burgerTime() {
        if (isClosed == true) {
            closeNav();
        } else {
            trigger.removeClass('is-closed');
            trigger.addClass('is-open');
            isClosed = true;

            openNav();
        }
    }

    function openNav() {
        $('.c-menu__block--top').animate({
            top: '0%'       
        }, 'slow','swing');

        $('.c-menu__block--bot').animate({
            bottom: '0%'       
        }, 'slow','swing');

        $('.c-menu__block--middle-bot').animate({
            height: '50%'      
        }, 'slow','swing');

        $('.c-menu__block--middle-top').animate({
            height: '50%',
            top: '0%'       
        }, 'slow','swing');

        setTimeout(function(){ 
            $('.c-nav').css('display', 'flex');

            $('.c-nav').animate({
                top: '12%',
                opacity: 1       
            }, 'slow','swing'); 
        }, 600);
    }

    // -- hide nav
    function closeNav() {
        $('.c-nav').animate({
            top: '15%',
            opacity: 0       
        }, 'slow','swing'); 

        setTimeout(function() {
            trigger.removeClass('is-open');
            trigger.addClass('is-closed');
            isClosed = false;
        }, 600);

        setTimeout(function(){ 
            $('.c-nav').css('display', 'none');

            $('.c-menu__block--top').animate({
                top: '-33%'       
            }, 'slow','swing');

            $('.c-menu__block--bot').animate({
                bottom: '-33%'       
            }, 'slow','swing');

            $('.c-menu__block--middle-bot').animate({
                height: '0%',    
            }, 'slow','swing');

            $('.c-menu__block--middle-top').animate({
                height: '0%',
                top: '50%'       
            }, 'slow','swing');
        }, 700);
    }

    function scrollToSection(section) {
        $('html, body').animate({
            scrollTop: section.offset().top - 95
        }, 2000);
    }

    // ----------- call functions -- on page load ----------- //
    checkSize();
    $(window).resize(checkSize);

    // ----------- when user clicks on burger menu ----------- //
    trigger.click(function () {
        burgerTime();
    });

    // ----------- click on nav link -- mob ----------- //
    $(document).on('click','#home-scroll-mob',function(){
        scrollToSection(heroSection);
        closeNav();
    });

    $(document).on('click','#about-scroll-mob',function(){
        scrollToSection(aboutSection);
        closeNav();
    });

    $(document).on('click','#work-scroll-mob',function(){
        scrollToSection(workSection);
        closeNav();
    });

    $(document).on('click','#testimonials-scroll-mob',function(){
        scrollToSection(testimonialsSection);
        closeNav();
    });

    $(document).on('click','#packages-scroll-mob',function(){
        scrollToSection(packagesSection);
        closeNav();
    });

    $(document).on('click','#contact-scroll-mob',function(){
        scrollToSection(contactSection);
        closeNav();
    });

    // ----------- click on nav link -- desktop ----------- //
    $(document).on('click','#home-scroll',function(){
        scrollToSection(heroSection);
        $('.c-nav__underline').css({'display': 'none'});
        $('.c-nav__underline--home').css({'display': 'block'});
    });

    $(document).on('click','#about-scroll',function(){
        scrollToSection(aboutSection);
        $('.c-nav__underline').css({'display': 'none'});
        $('.c-nav__underline--about').css({'display': 'block'});
    });

    $(document).on('click','#work-scroll',function(){
        scrollToSection(workSection);
        $('.c-nav__underline').css({'display': 'none'});
        $('.c-nav__underline--work').css({'display': 'block'});
    });

    $(document).on('click','#testimonials-scroll',function(){
        scrollToSection(testimonialsSection);
        $('.c-nav__underline').css({'display': 'none'});
        $('.c-nav__underline--testimonials').css({'display': 'block'});
    });

    $(document).on('click','#packages-scroll',function(){
        scrollToSection(packagesSection);
        $('.c-nav__underline').css({'display': 'none'});
        $('.c-nav__underline--packages').css({'display': 'block'});
    });

    $(document).on('click','#contact-scroll',function(){
        scrollToSection(contactSection);
        $('.c-nav__underline').css({'display': 'none'});
        $('.c-nav__underline--contact').css({'display': 'block'});
    });

    // ----------- our work slider ----------- //
    $('.c-slider--work').slick({
        arrows: false,
        autoplay: true,
        dots: true
    });

    // ----------- testimonials slider ----------- //
    $('.c-slider--testimonials').slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000
    });

    if ($(".burger").css("display") == "none" ) {
        $.instagramFeed({
            'username': 'digital.is.here',
            'container': "#instagramFeed",
            'display_profile': true,
            'display_biography': true,
            'display_gallery': true,
            'get_raw_json': false,
            'callback': null,
            'styling': true,
            'items': 8,
            'items_per_row': 4
        });

        setTimeout(function(){ 
            $('.instagram_gallery').slick({
                arrows: false,
                autoplay: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                rows: 2
            });
        }, 2000);
    } else {
        $.instagramFeed({
            'username': 'digital.is.here',
            'container': "#instagramFeed",
            'display_profile': true,
            'display_biography': true,
            'display_gallery': true,
            'get_raw_json': false,
            'callback': null,
            'styling': true,
            'items': 8,
            'items_per_row': 2
        });

        setTimeout(function(){ 
            $('.instagram_gallery').slick({
                arrows: false,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToShow: 2,
                slidesToScroll: 2,
                rows: 2
            });
        }, 2000);
    }

    // ----------- form input animations ----------- //
    $('input').focus(function() {
        $(this).parent().addClass('active');

        $('input').focusout(function() {
            if ($(this).val() == '') { 
                $(this).parent().removeClass('active');
            } else { 
                $(this).parent().addClass('active');
            }
        });
    });
});