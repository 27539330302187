$('document').ready(function () {

    var form = $('.c-form');
    var formMsg = $('.c-message');

    $(form).submit(function(event) {

        event.preventDefault();
        var formData = $(form).serialize();

        $.ajax({
            type: 'POST',
            url: $(form).attr('action'),
            data: formData
        })
        .done(function(response) {

            // ----------- form envelope animations ----------- //
            $('.c-form__letter').toggleClass('move');
            $('.c-form__mail--top').toggleClass('closed');
            $('.c-form__btn--invisible').toggleClass('visible');
            $('.c-form__btn--visible').toggleClass('invisible');


            // add success class to the btn
            //$('.c-form__submit').addClass('c-msg--success');

            // change the submit btn text
            //$('input.c-form__submit').val('Thank You');

            // clear error messages
            $('.c-message').text('');

            // clear form data
            $('#name').val('');
            $('#phone').val('');
            $('#email').val('');
            $('#message').val('');

            // reset google recaptcha
            grecaptcha.reset();
        })
        .fail(function(data) {

            // Set the message text
			if (data.responseText !== '') {
				$(formMsg).text(data.responseText);
			} else {
				$(formMsg).text('Oops! An error occured and your message could not be sent.');
			}

        });
    });
});